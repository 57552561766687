export const PermissaoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO",
	CRIAR: "CRIAR_USUARIO",
	EXCLUIR: "EXCLUIR_USUARIO",
};

export const PermissaoGrupoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_EMPRESA",
	CRIAR: "CRIAR_EMPRESA",
	EXCLUIR: "EXCLUIR_EMPRESA",
};

export const PermissaoProdutoEnum = {
	VISUALIZAR: "VISUALIZAR_PRODUTO",
	CRIAR: "CRIAR_PRODUTO",
	EXCLUIR: "EXCLUIR_PRODUTO",
	VISUALIZAR_APENAS_EMPRESA: "VISUALIZAR_PRODUTO_APENAS_EMPRESA",
};

export const PermissaoTesteUnitarioEnum = {
	VISUALIZAR: "VISUALIZAR_TESTE_UNITARIO",
	CRIAR: "CRIAR_TESTE_UNITARIO",
	EXCLUIR: "EXCLUIR_TESTE_UNITARIO",
};

export const PermissaoConfiguracaoTesteEnum = {
	VISUALIZAR: "VISUALIZAR_CONFIGURACAO_TESTE",
	CRIAR: "CRIAR_CONFIGURACAO_TESTE",
	EXCLUIR: "EXCLUIR_CONFIGURACAO_TESTE",
	VISUALIZAR_APENAS_EMPRESA: "VISUALIZAR_CONFIGURACAO_TESTE_APENAS_EMPRESA",
};

export const PermissaoCategoriaProdutoEnum = {
	VISUALIZAR: "VISUALIZAR_CATEGORIA_PRODUTO",
	CRIAR: "CRIAR_CATEGORIA_PRODUTO",
	EXCLUIR: "EXCLUIR_CATEGORIA_PRODUTO",
};

export const PermissaoModeloJigaEnum = {
	VISUALIZAR: "VISUALIZAR_MODELO_JIGA",
	CRIAR: "CRIAR_MODELO_JIGA",
	EXCLUIR: "EXCLUIR_MODELO_JIGA",
};

export const PermissaoUnidadeEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_UNIDADE_EMPRESA",
	CRIAR: "CRIAR_UNIDADE_EMPRESA",
	EXCLUIR: "EXCLUIR_UNIDADE_EMPRESA",
};

export const PermissaoJigaEnum = {
	VISUALIZAR: "VISUALIZAR_JIGA",
	CRIAR: "CRIAR_JIGA",
	EXCLUIR: "EXCLUIR_JIGA",
	VISUALIZAR_APENAS_EMPRESA: "VISUALIZAR_JIGA_APENAS_EMPRESA",
	VISUALIZAR_APENAS_UNIDADE_EMPRESA: "VISUALIZAR_JIGA_APENAS_UNIDADE_EMPRESA",
};

export const PermissaoLogTesteEnum = {
	VISUALIZAR: "VISUALIZAR_LOG_TESTE",
	CRIAR: "CRIAR_LOG_TESTE",
	EXCLUIR: "EXCLUIR_LOG_TESTE",
	VISUALIZAR_APENAS_EMPRESA: "VISUALIZAR_LOG_TESTE_APENAS_EMPRESA",
	VISUALIZAR_APENAS_UNIDADE_EMPRESA:
		"VISUALIZAR_LOG_TESTE_APENAS_UNIDADE_EMPRESA",
	RODAR_TESTE: "RODAR_TESTE",
};
