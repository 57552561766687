import React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { setIsLoading } from "redux/actions/Gui";
import "./style.css";

/*
  Átomo de Loading. Mostra o spinner quando isLoading = true.
  Em geral, deve englobar todos os componentes da tela.
*/
function Loading(props) {
	const { isLoading, children } = props;
	return (
		<div
			style={{
				position: "fixed",
				width: "100%",
				height: "100%",
				display: isLoading ? "flex" : "none",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "rgba(0, 0, 0, 0.6)",
				zIndex: 5000,
			}}
		>
			<Spin size="large" spinning={isLoading} style={{ zIndex: 102 }}>
				{children}
			</Spin>
		</div>
	);
}

const mapStateToProps = ({ gui, auth }) => {
	return {
		isLoading: gui.isLoading,
		token: auth.token,
	};
};

const mapDispatchToProps = { setIsLoading };

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
