import {
	HomeOutlined,
	UserOutlined,
	UsergroupAddOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoGrupoUsuarioEnum,
	PermissaoUsuarioEnum,
	PermissaoEmpresaEnum,
	PermissaoProdutoEnum,
	PermissaoTesteUnitarioEnum,
	PermissaoConfiguracaoTesteEnum,
	PermissaoCategoriaProdutoEnum,
	PermissaoModeloJigaEnum,
	PermissaoUnidadeEmpresaEnum,
	PermissaoJigaEnum,
	PermissaoLogTesteEnum,
} from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "testes",
		path: `${APP_PREFIX_PATH}/testes`,
		title: "testes",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoLogTesteEnum.RODAR_TESTE,
	},
	{
		key: "lista-usuarios",
		path: `${APP_PREFIX_PATH}/lista-usuarios`,
		title: "lista-usuarios",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-jigas",
		path: `${APP_PREFIX_PATH}/lista-jigas`,
		title: "lista-jigas",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [
			PermissaoJigaEnum.VISUALIZAR,
			PermissaoJigaEnum.VISUALIZAR_APENAS_EMPRESA,
			PermissaoJigaEnum.VISUALIZAR_APENAS_UNIDADE_EMPRESA,
		],
	},
	{
		key: "lista-testes-unitarios",
		path: `${APP_PREFIX_PATH}/lista-testes-unitarios`,
		title: "lista-testes-unitarios",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoTesteUnitarioEnum.VISUALIZAR,
	},
	{
		key: "lista-configuracoes-teste",
		path: `${APP_PREFIX_PATH}/lista-configuracoes-teste`,
		title: "lista-configuracoes-teste",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [
			PermissaoConfiguracaoTesteEnum.VISUALIZAR,
			PermissaoConfiguracaoTesteEnum.VISUALIZAR_APENAS_EMPRESA,
		],
	},
	{
		key: "lista-logs-teste",
		path: `${APP_PREFIX_PATH}/lista-logs-teste`,
		title: "lista-logs-teste",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [
			PermissaoLogTesteEnum.VISUALIZAR,
			PermissaoLogTesteEnum.VISUALIZAR_APENAS_EMPRESA,
			PermissaoLogTesteEnum.VISUALIZAR_APENAS_UNIDADE_EMPRESA,
		],
	},
	{
		// showTitle: false,
		title: "secundario",
		submenu: [
			{
				key: "cadastro-secundario",
				title: "cadastro-secundario",
				breadcrumb: false,
				submenu: [
					{
						key: "lista-grupos-usuario",
						path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
						title: "lista-grupos-usuario",
						icon: UsergroupAddOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: PermissaoGrupoUsuarioEnum.VISUALIZAR,
					},
					{
						key: "lista-empresas",
						path: `${APP_PREFIX_PATH}/lista-empresas`,
						title: "lista-empresas",
						icon: HomeOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: PermissaoEmpresaEnum.VISUALIZAR,
					},
					{
						key: "lista-categorias-produto",
						path: `${APP_PREFIX_PATH}/lista-categorias-produto`,
						title: "lista-categorias-produto",
						icon: HomeOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: PermissaoCategoriaProdutoEnum.VISUALIZAR,
					},
					{
						key: "lista-produtos",
						path: `${APP_PREFIX_PATH}/lista-produtos`,
						title: "lista-produtos",
						icon: HomeOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: PermissaoProdutoEnum.VISUALIZAR,
					},
					{
						key: "lista-modelos-jiga",
						path: `${APP_PREFIX_PATH}/lista-modelos-jiga`,
						title: "lista-modelos-jiga",
						icon: HomeOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: PermissaoModeloJigaEnum.VISUALIZAR,
					},
					{
						key: "lista-unidades-empresa",
						path: `${APP_PREFIX_PATH}/lista-unidades-empresa`,
						title: "lista-unidades-empresa",
						icon: HomeOutlined,
						breadcrumb: false,
						submenu: [],
						permissao: PermissaoUnidadeEmpresaEnum.VISUALIZAR,
					},
				],
			},
		],
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
